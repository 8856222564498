











































































import Vue from "vue";
import { Getter } from "vuex-class";
import { Company, Employee } from "@/core/models";
import Upgrade from "../components/Upgrade.vue";
import AddEmployees from "../components/AddEmployees.vue";
import { Component, Watch } from "vue-property-decorator";
import CreateCompany from "../components/CreateCompany.vue";
import SingleEmployee from "../components/SingleEmployee.vue";
import EditCompanyName from "../components/EditCompanyName.vue";
import { isDev, isLocal } from "@/settings";

@Component({
  computed: {
    isDev() {
      return isDev;
    },
    isLocal() {
      return isLocal;
    },
  },
  components: {
    CreateCompany,
    EditCompanyName,
    Upgrade,
    AddEmployees,
    SingleEmployee,
  },
})
export default class CompanyDashboard extends Vue {
  @Getter("company/getting") getting!: boolean;
  @Getter("company/company") company!: Company;
  // @Getter("company/isActive") isActive!: boolean;
  @Getter("company/hasCompany") hasCompany!: boolean;
  @Getter("profile/getUserEmail") currentUserEmail!: string;
  @Getter("profile/getUserHasPrivileges") hasPrivileges!: boolean;

  get isOwner() {
    if (!this.company.Owner) return false;
    return this.company.Owner.Email === this.currentUserEmail;
  }
  get isManager() {
    return this.company.Employees.some(
      x => x.Email === this.currentUserEmail && x.IsManager,
    );
  }
  canManage(_e: Employee) {
    return this.hasPrivileges;
    // TODO For now we limit user management to admins only!
    // if (this.isOwner) return !e.IsOwner;
    // return this.isManager && (!e.IsManager || !e.IsOwner);
  }

  isActive(company: any) {
    if (company.subscription === null) return false;
    return company.subscription.isActive;
  }

  @Watch("hasCompany")
  redirect() {
    const homePath = "/company";
    if (!this.hasCompany) this.$router.push(homePath);
  }

  @Watch("isActive")
  activeChange(newValue: boolean) {
    console.log("New isActive value: ", newValue);
  }
}
